$(document).ready(function() {
  $("#categoryIcon").click(function() {
    window.toggleMenu();
  });

  $("#closeMenu").click(function() {
    $(".menu").addClass("menu--hidden")
    $(".categoryMenuOverlay").fadeOut()
  });
});

window.toggleMenu = function() {
  var menu = $(".menu")
  if (menu.hasClass("menu--hidden")) {
    menu.removeClass("menu--hidden")
    $(".categoryMenuOverlay").fadeIn()
  } else {
    menu.addClass("menu--hidden")
    $(".categoryMenuOverlay").fadeOut()
  }
}
