var currentPage = 1;
var InfiniteScroll = require('infinite-scroll');
import Rails from "@rails/ujs"
import { debounce } from "debounce"


function triggerAjaxSearchForm(e) {
  Rails.fire($('#ajaxSearchForm')[0], 'submit')
}

$(document).ready(function() {
  MicroModal.init();
  $("#notice").click(function() {
    $(this).fadeOut();
  });
  $("#loadMore").click(function() {
    document.dispatchEvent(new Event('loadMore-clicked'));
  });

  $('#ajaxSearchForm').on('ajax:before', function() {
    $("#ajaxSearchForm .fa-spinner").show();
  })
  $('#ajaxSearchForm').on('ajax:complete', function() {
    if($(".sampleSearch").val().length > 0){
      // If the search box is not empty, infinite loading should be disabled
      infScroll.option( {loadOnScroll: false} )
    }else{
      // If it's empty, it should be re-enabled
      infScroll.option( {loadOnScroll: true} )
    }

    $("#ajaxSearchForm .fa-spinner").hide();
  })
  $('#ajaxSearchForm').on('keyup', debounce( triggerAjaxSearchForm, 250 ))


  if(window.enable_infinite_scroll){
    var csrf_token = $('meta[name="csrf-token"]').attr('content');
    var infScroll = new InfiniteScroll( '#samples-table', {
      // URL that will be called to load more data
      path: "/samples/load_more?page={{#}}",

      // Class of the elements that are expected in the Ajax response, and will be inserted on the page
      append: '.samples__row',

      // Extra security headers for Rails
      fetchOptions: {
        headers: {'X-CSRF-Token': csrf_token, 'X-Requested-With': 'XMLHttpRequest'}
      },

      // Make sure we don't rewrite the browser history / URL
      history: false
    });
  }

});

$(document).on('loadMore-clicked', function() {
  var current_page = $("#loadMore").attr("data-current-page");
  current_page++;
  loadMoreSamples(current_page);
});

$(document).on('click', '.samples__row', function() {
  showOrHideDetails(this);
});





function showOrHideDetails(sample) {
  var sample_id = $(sample).attr("data-id");
  // Sample detail is not loaded, load it.
  var sample_detail = $(sample).find(".sampleDetail");
  if (sample_detail.is(":empty")) { loadSampleDetail(sample_id) };

  if (sample_detail.hasClass("sampleDetail--hidden")) {
    // Fade in the div
    sample_detail.fadeIn({
      start: function () {
        $(this).css({ display: "grid"})
      }
    });
    // Remove the hidden class; we use this to check if the div is hidden or not
    sample_detail.removeClass("sampleDetail--hidden");
  } else {
    sample_detail.fadeOut();
    sample_detail.addClass("sampleDetail--hidden");
  }
};

function loadSampleDetail(sample_id) {
  $.ajax({
    type: "GET",
    url: "/samples/" + sample_id,
  });
};

function loadMoreSamples(index) {
  $.ajax({
    type: "GET",
    url: '/samples/load_more',
    data: { page: index }
  });
};
